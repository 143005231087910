// Accented Characters
const accentUppercase = [
  {
    name: "A, grave",
    symbol: "À",
    word: "&Agrave;",
    number: "&#192;",
  },
  {
    name: "A, acute",
    symbol: "Á",
    word: "&Aacute;",
    number: "&#193;",
  },
  {
    name: "A, circumflex",
    symbol: "Â",
    word: "&Acirc;",
    number: "&#194;",
  },
  {
    name: "A, tilde",
    symbol: "Ã",
    word: "&Atilde;",
    number: "&#195;",
  },
  {
    name: "A, umlaut",
    symbol: "Ä",
    word: "&Auml;",
    number: "&#196;",
  },
  {
    name: "A, ring",
    symbol: "Å",
    word: "&Aring;",
    number: "&#197;",
  },
  {
    name: "AE",
    symbol: "Æ",
    word: "&AElig;",
    number: "&#198;",
  },
  {
    name: "C, cedilla",
    symbol: "Ç",
    word: "&Ccedil;",
    number: "&#199;",
  },
  {
    name: "E, grave",
    symbol: "È",
    word: "&Egrave;",
    number: "&#200;",
  },
  {
    name: "E, acute",
    symbol: "É",
    word: "&Eacute;",
    number: "&#201;",
  },
  {
    name: "E, circumflex",
    symbol: "Ê",
    word: "&Ecirc;",
    number: "&#202;",
  },
  {
    name: "E, umlaut",
    symbol: "Ë",
    word: "&Euml;",
    number: "&#203;",
  },
  {
    name: "I, grave",
    symbol: "Ì",
    word: "&Igrave;",
    number: "&#204;",
  },
  {
    name: "I, acute",
    symbol: "Í",
    word: "&Iacute;",
    number: "&#205;",
  },
  {
    name: "I, circumflex",
    symbol: "Î",
    word: "&Icirc;",
    number: "&#206;",
  },
  {
    name: "I, umlaut",
    symbol: "Ï",
    word: "&Iuml;",
    number: "&#207;",
  },
  {
    name: "Eth (Icelandic)",
    symbol: "Ð",
    word: "&ETH;",
    number: "&#208;",
  },
  {
    name: "N, tilde",
    symbol: "Ñ",
    word: "&Ntilde;",
    number: "&#209;",
  },
  {
    name: "Latin OE ligature",
    symbol: "Œ",
    word: "&OElig;",
    number: "&#338;",
  },
  {
    name: "O, grave",
    symbol: "Ò",
    word: "&Ograve;",
    number: "&#210;",
  },
  {
    name: "O, acute",
    symbol: "Ó",
    word: "&Oacute;",
    number: "&#211;",
  },
  {
    name: "O, circumflex",
    symbol: "Ô",
    word: "&Ocirc;",
    number: "&#212;",
  },
  {
    name: "O, tilde",
    symbol: "Õ",
    word: "&Otilde;",
    number: "&#213;",
  },
  {
    name: "O, umlaut",
    symbol: "Ö",
    word: "&Ouml;",
    number: "&#214;",
  },
  {
    name: "O, slash",
    symbol: "Ø",
    word: "&Oslash;",
    number: "&#216;",
  },
  {
    name: "Latin S w/ caron",
    symbol: "Š",
    word: "&Scaron;",
    number: "&#352;",
  },
  {
    name: "U, grave",
    symbol: "Ù",
    word: "&Ugrave;",
    number: "&#217;",
  },
  {
    name: "U, acute",
    symbol: "Ú",
    word: "&Uacute;",
    number: "&#218;",
  },
  {
    name: "U, circumflex",
    symbol: "Û",
    word: "&Ucirc;",
    number: "&#219;",
  },
  {
    name: "U, umlaut",
    symbol: "Ü",
    word: "&Uuml;",
    number: "&#220;",
  },
  {
    name: "Y, acute",
    symbol: "Ý",
    word: "&Yacute;",
    number: "&#221;",
  },
  {
    name: "Latin Y w/ diaeresis",
    symbol: "Ÿ",
    word: "&Yuml;",
    number: "&#376;",
  },
  {
    name: "THORN (Icelandic)",
    symbol: "Þ",
    word: "&THORN;",
    number: "&#222;",
  },
];

// Lowercase
const accentLowercase = [
  {
    name: "a, grave",
    symbol: "à",
    word: "&agrave;",
    number: "&#224;",
  },
  {
    name: "a, acute",
    symbol: "á",
    word: "&aacute;",
    number: "&#225;",
  },
  {
    name: "a, circumflex",
    symbol: "â",
    word: "&acirc;",
    number: "&#226;",
  },
  {
    name: "a, tilde",
    symbol: "ã",
    word: "&atilde;",
    number: "&#227;",
  },
  {
    name: "a, umlaut",
    symbol: "ä",
    word: "&auml;",
    number: "&#228;",
  },
  {
    name: "a, ring",
    symbol: "å",
    word: "&aring;",
    number: "&#229;",
  },
  {
    name: "ae",
    symbol: "æ",
    word: "&aelig;",
    number: "&#230;",
  },
  {
    name: "c, cedilla",
    symbol: "ç",
    word: "&ccedil;",
    number: "&#231;",
  },
  {
    name: "e, grave",
    symbol: "è",
    word: "&egrave;",
    number: "&#232;",
  },
  {
    name: "e, acute",
    symbol: "é",
    word: "&eacute;",
    number: "&#233;",
  },
  {
    name: "e, circumflex",
    symbol: "ê",
    word: "&ecirc;",
    number: "&#234;",
  },
  {
    name: "e, umlaut",
    symbol: "ë",
    word: "&euml;",
    number: "&#235;",
  },
  {
    name: "i, grave",
    symbol: "ì",
    word: "&igrave;",
    number: "&#236;",
  },
  {
    name: "i, acute",
    symbol: "í",
    word: "&iacute;",
    number: "&#237;",
  },
  {
    name: "i, circumflex",
    symbol: "î",
    word: "&icirc;",
    number: "&#238;",
  },
  {
    name: "i, umlaut",
    symbol: "ï",
    word: "&iuml;",
    number: "&#239;",
  },
  {
    name: "Latin oe ligature",
    symbol: "œ",
    word: "&oelig;",
    number: "&#339;",
  },
  {
    name: "eth (Icelandic)",
    symbol: "ð",
    word: "&eth;",
    number: "&#240;",
  },
  {
    name: "n, tilde",
    symbol: "ñ",
    word: "&ntilde;",
    number: "&#241;",
  },
  {
    name: "o, grave",
    symbol: "ò",
    word: "&ograve;",
    number: "&#242;",
  },
  {
    name: "o, acute",
    symbol: "ó",
    word: "&oacute;",
    number: "&#243;",
  },
  {
    name: "o, circumflex",
    symbol: "ô",
    word: "&ocirc;",
    number: "&#244;",
  },
  {
    name: "o, tilde",
    symbol: "õ",
    word: "&otilde;",
    number: "&#245;",
  },
  {
    name: "o, umlaut",
    symbol: "ö",
    word: "&ouml;",
    number: "&#246;",
  },
  {
    name: "o, slash",
    symbol: "ø",
    word: "&oslash;",
    number: "&#248;",
  },
  {
    name: "Latin s w/ caron",
    symbol: "š",
    word: "&scaron;",
    number: "&#353;",
  },
  {
    name: "sharp s (German)",
    symbol: "ß",
    word: "&szlig;",
    number: "&#223;",
  },
  {
    name: "u, grave",
    symbol: "ù",
    word: "&ugrave;",
    number: "&#249;",
  },
  {
    name: "u, acute",
    symbol: "ú",
    word: "&uacute;",
    number: "&#250;",
  },
  {
    name: "u, circumflex",
    symbol: "û",
    word: "&ucirc;",
    number: "&#251;",
  },
  {
    name: "u, umlaut",
    symbol: "ü",
    word: "&uuml;",
    number: "&#252;",
  },
  {
    name: "y, acute",
    symbol: "ý",
    word: "&yacute;",
    number: "&#253;",
  },
  {
    name: "y, umlaut",
    symbol: "ÿ",
    word: "&yuml;",
    number: "&#255;",
  },
  {
    name: "thorn (Icelandic)",
    symbol: "þ",
    word: "&thorn;",
    number: "&#254;",
  },
];

export { accentUppercase, accentLowercase };  